<template>
  <promise invoke="getClient" :args="{ id: $route.params.id }" :key="$route.params.id" @done="ready" @ready="updateTitle">
    <template #content="props">
      <header class="main-header">
        <div class="row no-gutters align-items-center">
          <div class="col-auto">
            <Avatar :string="props.data.clientName" class="userinfo__image-container--xl"/>
          </div>
          <div class="col-auto">
            <h1 class="main-header__title">{{ props.data.clientName }}</h1>
          </div>
        </div>
      </header>
      <section class="main-content">
        <div class="row">
          <div class="col-lg-7 mb-5 mb-lg-0">
            <documents endpoint="client" :documents="props.data.clientDocuments"
                           @add="data => addClientDocument(props, data)"
                           @delete="document => deleteClientDocument(props, document)"/>
          </div>
          <div class="col-lg-5">
            <!-- key contacts -->
            <Contacts title="Key Client Contacts" :clientName="props.data.clientName" :clientContacts="props.data.contacts"/>
          </div>
        </div>

        <!-- quotation -->
        <modal v-for="quote in props.data.quotes" :key="quote.id" :id="`view-quote-${slugifyQuote(quote)}`" class="modal--lg modal-view-quote" @close="quoteModalClosed">
          <template #title>Quote {{ quote.ID }} - {{ quote.Name }} <span class="status-bubble status-bubble--sm ml-2" style="vertical-align:middle">{{ quote.status }}</span></template>
          <template #content>
            <div class="row h-100">
              <div class="col-8">
                <object :data="`https://stg.dashboard.apps.welovechatter.com/quote/${props.data.clientUUID}/${slugifyQuote(quote)}/pdf`" type="application/pdf" class="pdf-preview">
                  <!--<iframe src="https://docs.google.com/viewer?url=your_url_to_pdf&embedded=true"></iframe>-->
                </object>
              </div>
              <div class="col-4">
                <div v-if="quote.status === 'unsent'" class="quote__send mb-4 pb-4">
                  <!--<div class="quote__send__status">
                    <h4 class="mt-0 mb-1">Sent</h4>
                    <p>This quote was sent to <strong>Chatter (invoices@welovechatter.com)</strong> by <strong>Aaron Kirkham</strong> on the 15th November 2022.</p>
                  </div>

                  <div class="quote__send__status quote__send__status--awaiting">
                    <h4 class="mt-0 mb-1">Waiting for Client</h4>
                    <p>This quote was sent to <strong>Chatter (invoices@welovechatter.com)</strong> by <strong>Aaron Kirkham</strong> on the 15th November 2022.</p>
                  </div>-->

                  <h3 class="mt-0 mb-0">Send Quote</h3>
                  <p class="heading-desc mt-1 mb-3 pb-3">Once you're happy with this quote, select a recipient, write a message and hit Send.</p>
                  <ajax-form :url="`/quote/${props.data.clientUUID}/${slugifyQuote(quote)}/send`" buttonText="Send" @done="result => updateQuoteStatus(quote, result)">
                    <template #fields>
                      <div class="form-group">
                        <label :for="`quote-signoff-recipient-${quote.ID}`">Recipient <abbr class="required">*</abbr> <span class="desc">Select a Contact which will be asked to sign off the quote.</span></label>
                        <select name="client_recipient_uuid" :id="`quote-signoff-recipient-${quote.ID}`" class="form-select" required>
                          <option value="" selected disabled>Select a Contact</option>
                          <option v-for="contact in props.data.contacts"
                                  :key="contact.contactUUID"
                                  :value="contact.contactUUID">{{ contact.name }} ({{ contact.email }})</option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label :for="`quote-signoff-message-${quote.ID}`">Message</label>
                        <textarea name="chatter_commentary" :id="`quote-signoff-message-${quote.ID}`" class="form-control" rows="6" placeholder="Enter your message"></textarea>
                      </div>
                    </template>
                  </ajax-form>
                </div>
                <div v-else class="quote__comments">
                  <h3 class="mt-0 mb-0">Comments</h3>
                  <p class="heading-desc mt-1 mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pharetra, odio vel porttitor tempor, metus augue.</p>
                  <div class="quote__comments__container mt-4">
                    <div class="quote__comments__comment mb-3">
                      <h4 class="mt-0 mb-1">{{ getChatterinoName(quote.chatter_sender_uuid) }} <span class="heading-desc">&mdash; 18/02/2021</span></h4>
                      <div class="quote__comments__comment__inner" v-html="quote.chatter_commentary"/>
                    </div>
                    <div v-if="quote.client_comments" class="quote__comments__comment">
                      <h4 class="mt-0 mb-1">{{ getContactName(props, quote.client_recipient_uuid) }} <span class="heading-desc">&mdash; 24/02/2021</span></h4>
                      <div class="quote__comments__comment__inner" v-html="quote.client_comments"/>
                    </div>
                  </div>

                  <!-- comment form -->
                  <!--<ajax-form :url="`/quote/a50cae26-9c0d-4fa0-865d-9d85053afbed/${slugifyQuote(quote)}/comment`" buttonText="Post" class="quote__comments__comment-form mt-4 pt-4">
                    <template #fields>
                      <div class="form-group">
                        <label :for="`comment-msg-${quote.ID}`">Comment</label>
                        <textarea :id="`comment-msg-${quote.ID}`" name="comment_msg" class="form-control" rows="4" placeholder="Enter your comment here..."></textarea>
                      </div>
                    </template>
                  </ajax-form>-->
                </div>
              </div>
            </div>
          </template>
        </modal>
        <h3 class="mt-5 mb-3">Quotation</h3>
        <table class="table table--sm table--align-left" v-table-sort="fn => props.data.quotes.sort(fn)">
          <thead>
            <tr>
              <th width="12%">Quote Number</th>
              <th width="30%">Name</th>
              <th width="10%">State</th>
              <th width="12%" class="string" v-column-sort:Date>Date</th>
              <th width="15%" class="money" v-column-sort:AmountIncludingTax>Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="quote in props.data.quotes" :key="quote.id">
              <td>
                <router-link :to="`/client/${props.data.clientUUID}/quote/${slugifyQuote(quote)}`">{{ quote.ID }}</router-link>
              </td>
              <td>
                <router-link :to="`/client/${props.data.clientUUID}/quote/${slugifyQuote(quote)}`">{{ quote.Name }}</router-link>
              </td>
              <td class="capitalize">{{ quote.status }}</td>
              <td>{{ formatDate(quote.Date) }}</td>
              <td>{{ formatPrice(quote.AmountIncludingTax) }}</td>
            </tr>
            <!-- empty -->
            <tr v-if="isEmptyObject(props.data.quotes)" class="table__empty">
              <td colspan="5">No quotes have been added.</td>
            </tr>
          </tbody>
        </table>

        <!-- current jobs -->
        <h3 class="mt-5">Current Jobs</h3>
        <table class="table table--sm table--align-left" v-table-sort="fn => props.data.jobs.sort(fn)">
          <thead>
            <tr>
              <th width="12%">Job Number</th>
              <th>Job Name</th>
              <th width="15%" class="string" v-column-sort:startDate>Start Date</th>
              <th width="18%" class="string" v-column-sort:manager>Account Handler</th>
              <th width="12%" class="string" v-column-sort:state>State</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="job in props.data.jobs" :key="job.jobUUID">
              <td><router-link :to="`/project/${job.jobUUID}`">{{ job.jobRef }}</router-link></td>
              <td><router-link :to="`/project/${job.jobUUID}`">{{ job.jobName }}</router-link></td>
              <td>{{ formatDate(job.startDate) }}</td>
              <td>{{ job.manager }}</td>
              <td>{{ job.state }}</td>
            </tr>
          </tbody>
        </table>
      </section>
    </template>
  </promise>
</template>

<script>
import { ref, computed } from 'vue'
import { useMeta } from 'vue-meta'
import { mapState } from 'vuex'
import Promise from '@/components/promise.vue'
import Modal from '@/components/modal.vue'
import Avatar from '@/components/avatar.vue'
import Contacts from '@/components/contacts.vue'
import AjaxForm from '@/components/ajax-form.vue'
import Documents from '@/components/chatter/documents.vue'
import {
  isEmptyObject,
  formatDate,
  formatPrice,
  slugifyQuote,
  unslugifyQuote,
} from '@/utils'

export default {
  components: {
    Promise,
    Modal,
    Avatar,
    Contacts,
    AjaxForm,
    Documents,
  },
  setup() {
    // TODO : use metaInfo() once https://github.com/nuxt/vue-meta/issues/699 is resolved.
    const title = ref('');
    const updateTitle = (data) => title.value = `${data.clientName}`;
    useMeta(computed(() => ({ title: title.value })));

    return { updateTitle };
  },
  data() {
    return {
      lastOpenModal: null,
    };
  },
  methods: {
    isEmptyObject, formatDate, formatPrice, slugifyQuote, unslugifyQuote,
    ready(data) {
      let { quote_ref } = this.$route.params;
      if (quote_ref) {
        // find quote
        const ref = unslugifyQuote(quote_ref);
        const quote = Object.keys(data.quotes).find(quote => data.quotes[quote].quote_ref === ref);
        if (quote) {
          this.lastOpenModal = `view-quote-${quote_ref}`;
          this.$nextTick(() => this.openModal(this.lastOpenModal));
        }
      }
    },
    /**
     * Get the name of a contact from a contact UUID
     */
    getContactName(props, uuid) {
      const result = props.data.contacts.find(contact => contact.contactUUID === uuid);
      return result?.name;
    },
    /**
     * Get the name of a chatterino from a UUID
     */
    getChatterinoName(uuid) {
      const chatterino = this.chatterinos.find(user => user.wfm_staff_uuid === uuid);
      return chatterino?.name;
    },
    /**
     * Project update was updated
     */
    updateQuoteStatus(quote, { result, formData }) {
      quote.status = 'sent';
      quote.status_updated_at = new Date().toLocaleDateString('en-gb');
      quote.client_recipient_uuid = formData.get('client_recipient_uuid');
      quote.chatter_commentary = formData.get('chatter_commentary');
      quote.chatter_sender_uuid = result.chatter_sender_uuid;
    },
    addClientDocument({ data }, { document_uuid, formData }) {
      data.clientDocuments.push({
        document_uuid,
        title: formData.get('title'),
        document_type: formData.get('document_type'),
        url: formData.get('url'),
        original_filename: 'TODO', // TODO
        shareable_with_client: formData.get('shareable_with_client') == 1 ? true : false,
      });
    },
    deleteClientDocument({ data }, { document_uuid }) {
      const index = data.clientDocuments.findIndex(document => document.document_uuid === document_uuid);
      if (index !== -1) {
        data.clientDocuments.splice(index, 1);
      }
    },
    quoteModalClosed() {
      const { id } = this.$route.params;
      this.$router.push(`/client/${id}`);
    },
  },
  watch: {
    '$route': function() {
      const { quote_ref } = this.$route.params;
      if (quote_ref) {
        this.lastOpenModal = `view-quote-${quote_ref}`;
        this.$nextTick(() => this.openModal(this.lastOpenModal));
      } else if (this.lastOpenModal) {
        this.closeModal(this.lastOpenModal);
        this.lastOpenModal = null;
      }
    },
  },
  computed: mapState([
    'chatterinos',
  ]),
}
</script>